import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import config from "../../config/main.config";
import {CardGroup} from "semantic-ui-react";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {getAllCurrentAppointments, mailtoBody} from "./util";
import sharedConfig from "../../../shared/sharedConfig";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {jumpToTop} from "../../helper/util";
import dayjs from "dayjs";
import history from "../../helper/browserHistory";
import i18n from "../../i18n";

function showAllAppointments(allAppointments, lang) {
    /* The checking for appointen.description.length is there do look if there is a description for this appointment. the default length of the description is 11 because if empty the description contains a <p><br></p>*/
    return allAppointments.sort((a, b) => {
        return a.dateStart.localeCompare(b.dateStart)
    }).map((appointment, index) =>
        <Card style={appointment.description.length > 11 ? {cursor: 'pointer'} : {}}>
            <div onClick={_ => {
                if (appointment.description.length > 11) {
                    history.push(`/${lang}/termine/${appointment.id}`)
                }
            }}>
                {appointment.thumbnail ? <picture className={'previewImage'}>
                        <source media={"(min-width: 1024px)"}
                                srcSet={config.BASE_URL_IMAGES + "desktop/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.')) + `.webp ${sharedConfig.IMAGE_SIZES.LARGE}w`
                                    + "," + config.BASE_URL_IMAGES + "desktop/" + appointment.thumbnail}/>
                        <source media={"(min-width: 321px)"}
                                srcSet={config.BASE_URL_IMAGES + "tablet/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.')) + `.webp ${sharedConfig.IMAGE_SIZES.MEDIUM}w`
                                    + "," + config.BASE_URL_IMAGES + "tablet/" + appointment.thumbnail}/>
                        <source media={"(max-width: 320px)"}
                                srcSet={config.BASE_URL_IMAGES + "mobile/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.')) + `.webp ${sharedConfig.IMAGE_SIZES.SMALL}w`
                                    + "," + config.BASE_URL_IMAGES + "mobile/" + appointment.thumbnail}/>
                        <img itemProp={'image'}
                             className={'previewImage'}
                             src={config.BASE_URL_IMAGES + 'all-images/' + appointment.thumbnail}
                             alt={appointment.thumbnail}/>
                    </picture> :
                    <img className={'previewImage'} style={{padding: '2rem'}}
                         src={config.BASE_URL_IMAGES + "Favicon_Hospiz.svg"} alt={"card image"}/>
                }
            </div>
            <Card.Header itemProp={'headline'} onClick={_ => {
                if (appointment.description.length > 11) {
                    history.push(`/${lang}/termine/${appointment.id}`)
                }
            }}>
                <h2 style={{marginLeft: '.5rem'}}>{appointment.name}</h2>

            </Card.Header>
            <Card.Content onClick={_ => {
                if (appointment.description.length > 11) {
                    history.push(`/${lang}/termine/${appointment.id}`)
                }
            }}>
                <p itemProp="datePublished">
                    {dayjs(appointment.dateStart).format('dddd') + " den " + dayjs(appointment.dateStart).format('DD.MM.YYYY')}<br/>
                    {appointment.dateStart !== "" ? "Von " + dayjs(appointment.dateStart).format('HH:mm') : ""}{appointment.dateEnd !== "" ? " bis " + dayjs(appointment.dateEnd).format('HH:mm') : ""}
                </p>
                {appointment.location && <p className={"flex"} style={{alignItems: "center"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-pin" width="24"
                         style={{marginRight: "5px"}}
                         height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                         stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                        <path
                            d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                    </svg>
                    {appointment.location}
                </p>}
                <span
                    style={appointment.fullyBooked ? {color: 'red'} : {color: 'green'}}>{appointment.fullyBooked ? 'Keine Plätze frei' : 'Plätze frei'}</span>
            </Card.Content>
            {appointment.registerRequired === 1 ? <Card.Content extra>
                <a style={{zIndex: 100}}
                   href={`mailto:${appointment.contact}?subject=${mailtoBody(appointment, 'subject')}&body=${mailtoBody(appointment, 'body')}`}>
                    <Button fluid disabled={appointment.fullyBooked}>Anmelden</Button>
                </a>
            </Card.Content> : <Card.Content extra>
                <Button fluid disabled>Keine Anmeldung erforderlich</Button>
            </Card.Content>}
        </Card>
    )

}


const AppointmentPage = () => {
    const [allAppointments, setAllAppointments] = useState([]);

    useEffect(_ => {
        getAllCurrentAppointments().then(appointments => {
            setAllAppointments(appointments);
        });
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, []);

    return (
        <div className={'grid ui'} style={{marginBottom: '200px', paddingTop: '200px', minHeight: '700px'}}>
            <div className={'row centered'}>
                <h1>Termine & Veranstaltungen</h1>
            </div>
            {allAppointments.length === 0 ? <div className={'row centered page-content-wrapper'}>
                <div className={'sixteen wide computer tablet fourteen wide mobile column '}>
                    <div>
                        <div>
                            <Message info style={{textAlign: "center"}}>
                                <Message.Header>Keine Aktuellen Veranstaltungen</Message.Header>
                                <p>Momentan gibt es keine aktuellen veranstaltungen <Icon name={"frown outline"}/></p>
                            </Message>
                        </div>
                    </div>
                </div>
            </div> : null}
            <div className={'row centered page-content-wrapper'}>
                <div className={'sixteen wide column'}>
                    <CardGroup className={'margin-auto'} style={{justifyContent: 'center'}}>
                        {showAllAppointments(allAppointments, i18n.language)}
                    </CardGroup>
                </div>
            </div>

        </div>

    )
}

AppointmentPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'termine').then((text) => {
        return text;
    })
}

export default withTranslation()(AppointmentPage)